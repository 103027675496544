import { AdminPanelHeader, SideBar, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filters, addIconWhite } from 'res/images';

import { CommonAlert, Pagination, PostHeader, Loader, EmptyView, FilterChips, CoreInputBox } from 'whealth-core-web/components';
import FilterModal from '../components/FilterModal';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { checkObjectLength, genrageFilterParams, titlizeString } from 'whealth-core-web/components/Helper';
import ShowPatientList from './patient/ShowPatientList';
import PatientNotes from './patient/PatientNotes';
import PatientConversion from './patient/PatientConversion';
import { useFilter } from 'whealth-core-web/components/FilterContext';

function Patient() {
  const ApiClient = useApiManager();
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  // const [click, setClick] = useState('');
  const [status, setStatus] = useState({ link: '' });
  const [isClearVal, setIsClearVal] = useState(false);
  const [data, setData] = useState('');
  const [hideEmpty, setHideEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [usersCount, setUsersCount] = useState([]);
  const [countData, setCountData] = useState({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [filterData, setFilterData] = useState({ active_patients: 'true' });
  const { filterData, setFilterData } = useFilter();
  const [clearFiltersData, setClearFilterData] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const value = useRef('');

  const [showPatientNotes, setShowPatientNotes] = useState(false);
  const [showPatientConversion, setShowPatientConversion] = useState(false);
  const [patientCoversionId, setPatientCoversionId] = useState('');

  const [conversionKey, setConversionKey] = useState('');
  const [patientId, setPatientId] = useState('');
  const [content, setContent] = useState('');
  const [titleReal, setTitleReal] = useState('');
  const [noteCategory, setNoteCategory] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getAdminProfiles();
  }, [status, currentPage, filterData, searchStr]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getAdminProfilesCount();
      if (searchStr.length > 1) {
        getAdminProfiles();
      } else {
        getCounts();
      }
    }, 500);
  
    return () => {
      clearTimeout(handler);
    };
  }, [searchStr, status, currentPage, filterData]);

  useEffect(() => {
    getCounts();
  }, [countData]);

  const getCounts = () => {
    let newArr = Object.keys(countData).reverse((a, b) => a - b);
    let newData = [];
    newArr.map((item) => {
      let newObj = countData[item];
      let title = `${item} (${countData[item].count || 0})`;
      title = titlizeString(title);
      newObj.title = title;
      newObj.link = countData[item].uuid || '';
      newData.push(newObj);
    });

    setUsersCount(newData.sort((a, b) => a.order - b.order));
  };

  useEffect(() => {
    getAdminProfilesCount();
  }, [filterData]);

  useEffect(() => {
    if (location?.state?.showAlert) {
      setIsShowAlert(location.state.showAlert);
    }
  }, [location?.state?.showAlert]);

  const createFilterParams = (data, defaultObj) => {
    let ignoreKeys = ['author_data', 'admin_data', 'date', 'tags_data', 'pmsDate', 'pmsConsultDate', 'prescriptionDate'];
    let filterParams = { ...data };
    if (defaultObj) {
      filterParams = { ...filterParams, ...defaultObj };
    }
    genrageFilterParams(data, filterParams, ignoreKeys);
    return filterParams;
  };

  const getAdminProfilesCount = () => {
    let prevFilterData = JSON.parse(JSON.stringify(filterData));

    // Check if care_manager_lead is an array before joining
    if (Array.isArray(prevFilterData?.care_manager_lead)) {
      prevFilterData.care_manager_lead = prevFilterData.care_manager_lead.join(',');
    }
    let qryParams = createFilterParams(prevFilterData);
    qryParams.search_str = searchStr;
    ApiClient.getPatientCount(qryParams)
      .then((res) => {
        if (value.current === qryParams.search_str) {
          setCountData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAdminProfiles = () => {
    let defaultObj = {
      status: status.link,
      page: currentPage,
      page_size: '10',
    };
    let prevFilterData = JSON.parse(JSON.stringify(filterData));

    // Check if care_manager_lead is an array before joining
    if (Array.isArray(prevFilterData?.care_manager_lead)) {
      prevFilterData.care_manager_lead = prevFilterData.care_manager_lead.join(',');
    }

    let qryParams = createFilterParams(prevFilterData, defaultObj);
    qryParams.search_str = searchStr;
    setIsLoading(true);
    ApiClient.getPatients(qryParams)
      .then((res) => {
        if (value.current === qryParams.search_str) {
          setTotalPages(res.headers['total-pages']);
          setData(res.data);
          setIsLoading(false);
          setHideEmpty(false);
        }
      })
      .catch((err) => {
        setHideEmpty(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const headerData = [
    {
      title: 'Add Patient',
      icon: addIconWhite,
      status: true,
      onClick: () => navigation('/patient/new'),
    },
  ];

  const leftSideData = [
    {
      title: 'Apply Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
      onClick: () => {
        setShowFilter(true);
      },
    },
  ];

  const addExtraParm = () => {
    let startDate = new Date();
    let endDate = new Date();
    if (filterData.pmsConsultDate) {
      let newFilterData = { ...filterData };
      delete newFilterData.pmsConsultDate;
      setFilterData({ ...newFilterData });
    } else {
      setFilterData({ ...filterData, pmsConsultDate: [startDate, endDate] });
    }
  };

  const activePatientOnClick = () => {
    if (filterData.active_patients) {
      let newFilterData = { ...filterData };
      delete newFilterData.active_patients;
      setFilterData({ ...newFilterData });
    } else {
      setFilterData({ ...filterData, active_patients: 'true' });
    }
  };

  const clearFilters = () => {
    setFilterData(new Object());
    setClearFilterData(!clearFiltersData);
  };

  const renderFilterChips = useMemo(() => {
    const ignoreKeys = ['tag_ids', 'tags_data', 'category_ids', 'author_ids', 'active_patients'];

    if (checkObjectLength(filterData, ignoreKeys)) {
      return (
        <div className='mt-3'>
          <FilterChips clearData={clearFilters} setData={setFilterData} data={filterData} />
        </div>
      );
    } else {
      if (Object.keys(filterData).length > 0 && !filterData.today_date && !filterData.active_patients) {
        clearFilters();
      }
      return;
    }
  }, [filterData]);

  const renderDashBoardHeader = useMemo(() => {
    return (
      <DashBoardHeader
        filterData={filterData}
        onClick={addExtraParm}
        activePatientOnclick={activePatientOnClick}
        patienLeftbtn
        activePatientBtn
        leftData={leftSideData}
        data={headerData}
        placeholder='Select Order'
      />
    );
  }, [filterData]);

  const renderPostHeader = useMemo(
    () => (
      <PostHeader
        postLinks={usersCount}
        onClickHandle={(data) => {
          setCurrentPage(1);
          setStatus(data);
        }}
      />
    ),
    [usersCount]
  );
  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search a patient by name or mobile number'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const showPatient = () => (
    <div className='teamHeader stickyHeader'>
      <div className='headerFiles'>
        <AdminPanelHeader iconTitle='person_outline' title='Patient' searchBox={renderSearchBox()} />
        {renderDashBoardHeader}
      </div>
      <div className='hrLine' />
      <div className='teamBody'>
        <div className='mt-2'>
          <CommonAlert redux='patientCreate' />
        </div>
        {isShowAlert && (
          <div className='mt-3'>
            <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} />
          </div>
        )}

        {renderPostHeader}
        {renderFilterChips}
      </div>
    </div>
  );

  const renderEmptyView = () => {
    return <EmptyView hide={isLoading || hideEmpty} title='No data found' />;
  };

  const showPatients = useMemo(
    () => (
      <ShowPatientList
        searchStr={searchStr}
        isClearVal={isClearVal}
        setIsClearVal={setIsClearVal}
        data={data}
        setPatientId={setPatientId}
        setShowPatientNotes={setShowPatientNotes}
        showPatientNotes={showPatientNotes}
        renderStatusChange
        getAdminProfiles={getAdminProfiles}
        setConversionKey={setConversionKey}
        assignTeam
        setContent={setContent}
        setTitleReal={setTitleReal}
        setNoteCategory={setNoteCategory}
        patientId={patientId}
        setShowPatientConversion={setShowPatientConversion}
        showPatientConversion={showPatientConversion}
        patientCoversionId={patientCoversionId}
        setPatientCoversionId={setPatientCoversionId}
      />
    ),
    [data]
  );

  const patientBody = () => {
    return (
      <>
        {showPatient()}
        <div className='usersList wrapperContent'>
          {data?.length > 0 ? showPatients : renderEmptyView()}
          <div className='d-flex justify-content-end mb-4'>
            <Pagination data={data} length={countData?.all_patients?.count} totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          </div>
        </div>
      </>
    );
  };

  const getFilterData = (data) => {
    setFilterData(data);
    setCurrentPage(1);
  };

  const showFilters = useCallback(() => {
    return (
      <FilterModal
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        clearFiltersData={clearFiltersData}
        setClearFilterData={setClearFilterData}
        filter='patient'
        getData={getFilterData}
        data={filterData}
      />
    );
  }, [clearFiltersData, filterData, showFilter]);
  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {patientBody()}
      <Loader show={isLoading} />
      {showFilters()}
      {/* {showPatientNotesDetails()} */}
      <PatientNotes
        content={content}
        setContent={setContent}
        titleReal={titleReal}
        setTitleReal={setTitleReal}
        noteCategory={noteCategory}
        setNoteCategory={setNoteCategory}
        patientId={patientId}
        showPatientNotes={showPatientNotes}
      />
      <PatientConversion
        isClearVal={isClearVal}
        setIsClearVal={setIsClearVal}
        content={content}
        // getAdminProfiles={getAdminProfiles}
        setContent={setContent}
        titleReal={titleReal}
        setTitleReal={setTitleReal}
        noteCategory={noteCategory}
        setNoteCategory={setNoteCategory}
        patientId={patientCoversionId}
      />
    </div>
  );
}

export default Patient;
